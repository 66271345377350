<template>
  <v-card
    elevation="2"
    class="d-flex flex-column flex-sm-row flex-wrap flex-sm-nowrap justify-sm-space-around align-sm-center pa-2 my-10"
  >
    <v-select
      class="ma-2 white"
      :items="filiais"
      item-text="nome"
      item-value="id"
      v-model="filialSelecionada"
      label="Filial"
      outlined
      hide-details="true"
    ></v-select>
    <v-select
      :disabled="
        propriedadesDaFilial.length > 0 ||
        !filialSelecionada ||
        !propriedadesDaFilial.setores
      "
      class="ma-2 white"
      :items="propriedadesDaFilial.setores"
      item-text="nome"
      item-value="id"
      v-model="setorSelecionado"
      label="Setor"
      outlined
      hide-details="true"
    ></v-select>
    <v-select
      :disabled="
        (propriedadesDaFilial.length > 0 && !filialSelecionada) ||
        !filialSelecionada ||
        !propriedadesDaFilial.responsaveis
      "
      class="ma-2 white"
      :items="propriedadesDaFilial.responsaveis"
      item-text="nome"
      item-value="id"
      v-model="gestorSelecionado"
      label="Gestor"
      outlined
      hide-details="true"
    ></v-select>
    <v-select
      :disabled="
        (propriedadesDaFilial.length > 0 && !filialSelecionada) ||
        !filialSelecionada ||
        !propriedadesDaFilial.times
      "
      class="ma-2 white"
      :items="propriedadesDaFilial.times"
      item-text="nome"
      item-value="id"
      v-model="timeSelecionado"
      label="Time"
      outlined
      hide-details="true"
    ></v-select>
    <v-select
      :disabled="
        (propriedadesDaFilial.length > 0 && !filialSelecionada) ||
        !filialSelecionada ||
        !propriedadesDaFilial.cargos
      "
      class="ma-2 white"
      :items="propriedadesDaFilial.cargos"
      item-text="nome"
      item-value="id"
      v-model="cargoSelecionado"
      label="Cargo"
      outlined
      hide-details="true"
    ></v-select>
    <v-btn class="pa-2 ma-2" height="50" color="primary"
      >Filtrar</v-btn
    >
  </v-card>
</template>

<script>
export default {
  name: "filtroComponent",
  data() {
    return {
      filialSelecionada: null,
      setorSelecionado: null,
      gestorSelecionado: null,
      timeSelecionado: null,
      cargoSelecionado: null,
    };
  },

  created() {
    this.$store.dispatch("avd/initialize");
  },

  computed: {
    filiais: {
      get() {
        return this.$store.getters["avd/getFiliais"];
      },
    },
    propriedadesDaFilial() {
      const itemVazio = {
        setores: [],
        responsaveis: [],
        times: [],
        cargos: [],
      };

      const item = this.filiais.find((m) => m.id == this.filialSelecionada);

      return item ? item : itemVazio;
    },
  },
};
</script>

<style scoped></style>
