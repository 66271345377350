<template>
  <section>
    <!-- v-if="permissaoDoUsuario.role !== 'colaborador'" -->
    <FiltroComponent />

    <v-card class="mt-16 mx-10" elevation="0">
      <v-tabs class="border-bottom" v-model="tabs">
        <v-tabs-slider color="#162C40"></v-tabs-slider>
        <v-tab
          v-for="tab in tabsPainel"
          :key="tab.id"
          :href="`#tab-${tab.id}`"
          class="primary--text"
          :disabled="!tab.permissao"
        >
          <span class="color-legiio">{{ tab.nome }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabs">
        <v-tab-item
          v-for="tabItem in tabsPainel"
          :key="tabItem.id + 1"
          :value="'tab-' + tabItem.id"
          :disabled="!tabItem.permissao"
        >
          <component :is="tabItem.componente"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- <h1 class="title font-weight-medium text-center mt-10">
      Filtre para obter os resultados de avaliações!
    </h1> -->
  </section>
</template>

<script>
import FiltroComponent from "./../components/FiltroComponent.vue";

export default {
  name: "PainelAvd",
  components: {
    FiltroComponent,
  },
  data() {
    return {
      tabs: 1,
    };
  },

  computed: {
    tabsPainel: {
      get() {
        return this.$store.getters["avd/getTabsPainel"];
      },
    },
    permissaoDoUsuario: {
      get() {
        return this.$store.getters[
          "avd/getPermissaoDoUsuario"
        ];
      },
    },
  },
};
</script>

<style scoped>
.color-legiio {
  color: #162c40;
}

.border-bottom {
  border-bottom: #bec5cf 2px solid;
}
</style>
