import { VCard } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('FiltroComponent'),_c(VCard,{staticClass:"mt-16 mx-10",attrs:{"elevation":"0"}},[_c(VTabs,{staticClass:"border-bottom",model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTabsSlider,{attrs:{"color":"#162C40"}}),_vm._l((_vm.tabsPainel),function(tab){return _c(VTab,{key:tab.id,staticClass:"primary--text",attrs:{"href":("#tab-" + (tab.id)),"disabled":!tab.permissao}},[_c('span',{staticClass:"color-legiio"},[_vm._v(_vm._s(tab.nome))])])})],2),_c(VTabsItems,{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.tabsPainel),function(tabItem){return _c(VTabItem,{key:tabItem.id + 1,attrs:{"value":'tab-' + tabItem.id,"disabled":!tabItem.permissao}},[_c(tabItem.componente,{tag:"component"})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }